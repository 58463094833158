<template>
  <div id="app">
    <router-view/>
    <el-dialog custom-class="customClass" :visible.sync="showUploadLinks" width="400px">
      <div style="font-size: 16px">检查到新的链接:magnet:?xt=urn:btih:{{btih}}</div>
      <div style="font-size: 16px">是否执行上传操作？</div>
      <span slot="footer" class="dialog-footer">
      <el-button round @click="showUploadLinks=false">取消</el-button>
      <el-button round type="primary" @click="upload()">上传</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { createWebSocket } from "@/utils/socket";
import Bus from "@/utils/bus";
import store from "@/store";
import ClipboardDialog from "@/components/ClipboardDialog";
import {isBtih, isHttp, getBtih} from "@/utils/formatCheck";
import {getUserStatus} from "@/utils/getUserStatus";
import {apiRequest} from '@/api/api'
import {storage} from "@/utils/storage";
import {apiRequestBigInt} from '@/api/api'
import { Notification } from 'element-ui';
export default {
  components: {ClipboardDialog},
  data(){
    return{
      webJson:{},
      ts:0,
      showUploadLinks:false,
      links:"",
      btih:"",
      notificationsShown: [], // 存储已经弹出的通知信息
      closeflag:false,
    }
  },

  methods: {
    upload(){
      console.log("this.$route.path",this.$route.path)
      this.showUploadLinks=false
      if(this.$route.path==="/index"){
        Bus.$emit('uplinks', this.btih);
      }else {
        this.$router.push({name:'index',params: {btih:this.btih}})
      }


    },
    init() {
      createWebSocket(this.global_callback);
    },
    handleMessage(event) {
      // 设置消息内容，以便在模板中显示

      //let jsonObj = JSON.parse(event);
      var jsonString = JSON.parse(event.detail);
      this.webJson=jsonString
     // let jsonObj = JSON.stringify(event);
     // alert(jsonString.value)
      if(jsonString.channel && jsonString.channel == 'hideNotification'){
       //console.log("App hideNotification message");
        this.closeflag = true;
        this.closeAllNotifications();
      }else if(jsonString.channel && jsonString.channel == 'showNotification'){
       // console.log("App showNotification message");
        if (storage.getToken()) {
          this.fetchNotifications();
        } 
      }
      // 如果需要，可以在这里设置定时器清除消息

    },
    // websocket的回调函数，msg表示收到的消息
    global_callback(msg) {
     // console.log("websocket的回调函数收到服务器信息：" , msg);
      //console.log("websocket的回调函数收到服务器信息：" + JSON.stringify(msg));
      this.webJson=msg
      if(this.webJson.event==="clipboard"&&this.webJson.value!==""){
        if (isBtih(this.webJson.value)) {
          this.showUploadLinks=true
          this.links=this.webJson.value
          this.btih=getBtih(this.webJson.value)
        }
      }
    },
    fetchNotifications() {
    console.log("get_notifications start");
    this.closeflag = false;
    var params = {
      "request_type": "notice/get_notifications",
      "offset": 0
    };
    apiRequestBigInt(params)
      .then((res) => {
        if (res.code === "SUCCESS") {
          //console.log("get_notifications SUCCESS=");

          // 处理成功响应
          let delaytime = 0;
          let count = 0;
          // 遍历通知数组
          for (const notification of res.notifications) {
            // 对每个通知进行处理
            const notificationId = String(notification.notification_id);
            if(notification.unread === 1 && notification.notification_title === "反馈回复"){
              const notificationKey = `${notification.notification_id}`;
              if (!this.notificationsShown.includes(notificationKey)) {
                console.log("get_notifications notificationKey=",notificationKey);
                // 如果通知没有弹出过，则弹出通知
                setTimeout(() => {
                    this.$notify({
                      title: notification.notification_title,
                      message: notification.notification_text,
                      duration: 0,
                      onClose: () => {
                        // 在通知关闭时执行关闭函数
                        this.handleNotifyClose(notificationKey);
                      }
                    });
                    // 将已经弹出的通知信息存储到数组中
                    this.notificationsShown.push(notificationKey);
                  }, delaytime); // 设置延时时间，比如1000毫秒（1秒）
                  delaytime += 200;
                  count++;
                  if(count === 5){
                    break;
                  }
              }
            }
          }
        } else {
          // 处理失败响应
          console.log("get_notifications 请求失败");
        }
      })
      .catch((error) => {
        // 处理请求错误
        console.log("get_notificationsA请求出错:", error);
      });
    },
    handleNotifyClose(notificationKey) {
      const index = this.notificationsShown.indexOf(notificationKey);
      if (index !== -1) {
        this.notificationsShown.splice(index, 1);
      }
      if(this.closeflag === false){
        console.log("关闭通知，键值为：", notificationKey);
        this.readNotification(notificationKey);
      }
    },
    closeAllNotifications() {
      Notification.closeAll();
      // 清空notificationsShown数组
      this.notificationsShown = [];
    },
    readNotification(id){
      var params = {
      "request_type": "notice/read_notification",
      "notification_id": id // 将 id 转换为 BigInt 类型
    };
    //console.log("readNotification params=", params);
    apiRequest(params)
      .then((res) => {
        if (res.code === "SUCCESS") {
          // 处理成功响应
          //console.log("read_notification 请求成功");
          // 遍历通知数组
         
        } else {
          // 处理失败响应
          console.log("read_notification 请求失败");
        }
      })
      .catch((error) => {
        // 处理请求错误
        console.log("read_notification:", error);
      });

    },
  },
  beforeDestroy() {
    Bus.$off('tryopen')
    window.removeEventListener('app-message', this.handleMessage);
    this.closeflag = true;
    this.closeAllNotifications();
  },
  created() {
    this.init();
    Bus.$on('tryopen', (a)=>{
      if(a){
        this.init();
       // console.log("发送：",'未开启重试开启监听');
      }
    })
    window.addEventListener('app-message', this.handleMessage);
  },mounted() {
    let val=this
    let interval = setInterval(function () {

      if(store.state.user_expire_seconds!==0){
        //console.log("试用时间测试", store.state.user_expire_seconds--)
        store.state.user_expire_seconds--
        if(store.state.user_expire_seconds%10==0){
          getUserStatus();
        }
      }

      if (store.state.socket.status !== "open") {
        val.init();
       // console.log("定时器测试重连",'尝试连接')
      }
      else {
        //clearInterval(interval);
       // console.log("心跳",'已经链接')
      }
    }, 1000);
  }
}
</script>

<style>
html, body, #app {
  height: 100%;
}

body{
  margin: 0;
  padding: 0;
}
.customClass {
  border-radius: 12px;
}

</style>
