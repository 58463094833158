import axios from 'axios';
import {storage} from '../utils/storage.js'
import routes from "@/router";
import { Message } from 'element-ui';
import getErrorcode from "@/api/common";
import JSONBig from 'json-bigint';

export function apiRequest(data = {}) {
    var params = ({
        "timestamp": Date.parse(new Date()) / 1000,
        "os_type": "PC",
        "app_name": "cilizhai",
        "app_version": "1.2",
        "channel": "webstore",
        "token": storage.getToken()
    });
    var json = JSON.stringify(Object.assign(params, data));//JSON.stringify() 方法用于将 JavaScript 值转换为 JSON 字符串
    var contentType = 'application/x-www-form-urlencoded'
    return new Promise(function (resolve, reject) {
        axios.defaults.headers.post['Content-Type'] = contentType;
        axios.post('https://web.miao2021.cn/web_api/',json)
            .then(function (res) {
                resolve(res.data);
                if (res.data.code == "E_INVALID_TOKEN" || res.data.code == "E_UNLOGIN_USER") {
                    storage.setToken('')
                    //TODO跳转路由
                    routes.push('/auth/login')
                }
            })
            .catch(function (error) {
                Message.error("服务器连接失败，请检查网络设置");
                reject(error);
            });
    });
}
export function apiRequestBigInt(data = {}) {

    // 创建一个自定义的axios实例
    const instance = axios.create({
        
    });
    var params = ({
        "timestamp": Date.parse(new Date()) / 1000,
        "os_type": "PC",
        "app_name": "cilizhai",
        "app_version": "1.2",
        "channel": "webstore",
        "token": storage.getToken()
    });
    var json = JSON.stringify(Object.assign(params, data));
    var contentType = 'application/x-www-form-urlencoded';

    return new Promise(function (resolve, reject) {
        instance.defaults.headers.post['Content-Type'] = contentType;
        instance.post('https://web.miao2021.cn/web_api/', json,{
            transformResponse: [function (responseData) {
                try {
                    // 尝试使用JSONBig解析响应数据
                    return JSONBig.parse(responseData);
                } catch (error) {
                    // 解析失败时，返回原始数据
                    return responseData;
                }
            }]
        })
            .then(function (res) {
                // 在这里对响应数据进行处理
                //console.log("响应数据为：", res.data);
                resolve(res.data);

                // 处理token失效或未登录的情况
                if (res.data.code === "E_INVALID_TOKEN" || res.data.code === "E_UNLOGIN_USER") {
                    storage.setToken('');
                    //TODO: 跳转路由
                    // routes.push('/auth/login');
                }
            })
            .catch(function (error) {
                Message.error("服务器连接失败，请检查网络设置");
                reject(error);
            });
    });
}
