<template>
  <el-dialog  title="安全验证" :visible.sync="dialogVisible" width="400px"
             :before-close="closeDialog">
    <div style="text-align: center">
      <div style="display: flex">
      <el-input v-model="phoneCaptcha" placeholder="请输入右侧安全码">
      </el-input>
        <img  :src="codeUrl"  @click="code()" id="img">
      </div>
      <el-button style="margin-top: 30px;width: 100%"  type="primary" @click="check">验证</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  name: "LoginCapthca",
  props: {
    dialogVisible: {
      type: Boolean,
      required: false,
    },
    phone: {
      type: String,
      required: '',
    },
    type:{
      type:String,
      required: '',//login/register/reset
    }
  },
  watch:{
    dialogVisible:{
      handler(val,old){
        if(val){
          this.phoneCaptcha=''
          this.codeUrl=`https://web.miao2021.cn/web_api/code.php?r=${this.phone}&t=${new Date()}`
        }
      }
    }
  },
  data(){
    return{
      phoneCaptcha:'',
      showLoginCaptcha: true,
      showImg:true,
      codeUrl:`https://web.miao2021.cn/web_api/code.php?r=${this.phone}&t=${new Date()}`
    }
  },
  methods:{
    closeDialog() {
      //this.showLoginCaptcha = false
      this.phoneCaptcha=''
      this.$emit('v-close', null)
    },
    code(){
      this.codeUrl=`https://web.miao2021.cn/web_api/code.php?r=${this.phone}&t=${new Date()}`
    },
    check(){
      if(this.phoneCaptcha===''){
        this.$message.error("安全码不能为空")
        return
      }
      let obj={}
      obj.phoneCaptcha=this.phoneCaptcha
      obj.type=this.type
      this.$emit('v-close', obj)
    }
  }
}
</script>

<style scoped>
.customClass {
  border-radius: 12px;
}
</style>