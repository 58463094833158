export default getErrorCode
let mapObj=null
let lastReturn = null; // 记录上一次的返回值
let lastTime = null; // 记录上一次的时间
function getErrorCode(code) {
  if(mapObj==null){
    mapObj = new Map()
    mapObj.set("E_INVALID_TOKEN", "用户登录已过期，请重新登录");
    mapObj.set("E_INTERNAL_ERROR", "服务器内部错误");
    mapObj.set("E_UNLOGIN_USER", "用户未登录");
    mapObj.set("E_INVALID_PHONE", "手机号缺失或密码错误");
    mapObj.set("E_INVALID_FIELDS", "帐户信息错误");
    mapObj.set("E_LOGIN_ACCOUNT", "登录失败，请核对输入的信息");
    mapObj.set("E_INVALID_PASSWORD", "手机号缺失或密码错误");
    mapObj.set("E_ACCOUNT_DISABLED", "您登录的账号存在违规操作，已被暂停使用！");
    mapObj.set("E_INVALID_FILE_ID", "file_id 缺失或无效");
    mapObj.set("E_INVALID_BTIH", "链接缺失或无效");
    mapObj.set("E_PHONE_UNREGISTED", "手机号未注册");
    mapObj.set("E_CAPTCHA_TOO_SOON", "验证码发送过快");
    mapObj.set("E_SENDING_CAPTCHA", "验证码发送失败");
    mapObj.set("E_INVALID_PHONE", "手机号缺失或无效");
    mapObj.set("E_INVALID_PASSWORD", "密码缺失或无效");
    mapObj.set("E_INVALID_CAPTCHA", "验证码缺失或无效");
    mapObj.set("E_PHONE_UNREGISTERED", "手机号未注册");
    mapObj.set("E_INVALID_LINK", "链接缺失或无效");
    mapObj.set("E_INVALID_FILENAME", "文件名缺失或无效");
    mapObj.set("E_DST_EXISTS", "目的文件或目录已存在");
    mapObj.set("E_FILE_PATH_TOO_LONG", "文件路径过长");
    mapObj.set("E_INVALID_PATH", "文件路径错误");
    mapObj.set("E_STS_SESSION_TOKEN_LIMITATION_REACHED", "文件上传达到上限");
    mapObj.set("E_FEEDBACK_LIMITATION_REACHED", "您今日反馈次数已达到上限");
    mapObj.set("E_INVALID_FEEDBACK", "反馈内容不得超过150字");
    mapObj.set("E_PHONE_REGISTED", "该手机号已注册");
    mapObj.set("E_INVALID_FID", "fid缺失或无效");
    mapObj.set("E_INVALID_DIR_NAME", "文件名不合规不得包含'/'");
    mapObj.set("E_INVALID_SEARCH", "当前搜索内容为空，试试换个关键字吧！");
    mapObj.set("E_WRONG_CAPTCHA", "请输入正确的验证码");
    mapObj.set("E_SET_PASSWORD", "您当前未设置密码，安全度低，请先设置密码后进行登出。");
    mapObj.set("E_INVALID_PHONE_CAPTCHA", "验证码无效");
    mapObj.set("E_REACHED_UPPER_LIMIT", "添加已达到上限了");


  }
  const currentTime = Date.now();
  const errorMessage = mapObj.get(code);

  if (lastReturn === errorMessage && lastTime && (currentTime - lastTime < 500)) {
    return NonNullable; // 返回 NonNullable 以指示相同错误信息
  }

  lastReturn = errorMessage;
  lastTime = currentTime;

  return errorMessage;
}

