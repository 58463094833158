<template>
  <el-menu-item
    v-if="!item.hidden
      && !item.children
      && item.meta"
    :index="item.path"

  >
    <i :class="item.meta.icon"></i>
  <!--    <font-awesome-icon :icon="item.meta.icon" />-->
    <span slot="title">
      {{item.meta.title}}
    </span>
  </el-menu-item>
  <el-menu-item
      v-else-if="!item.hidden
      && item.children
      && item.meta&&item.name=='browser'"
      :index="item.path"
  >
    <i :class="item.meta.icon"></i>
    <!--    <font-awesome-icon :icon="item.meta.icon" />-->
    <span slot="title">
      {{item.meta.title}}
    </span>
  </el-menu-item>
  <el-submenu v-else-if="!item.hidden && item.children && item.meta &&item.name!='browser'"
    :index="item.path">
    <template slot="title">
      <i :class="item.meta.icon"></i>
      <span v-if="item.meta && item.meta.title" slot="title">{{ item.meta.title }}</span>
    </template>
    <template v-for="child in item.children">
      <el-menu-item v-if="!child.hidden && child.meta "  :index="resolvePath(child.path)" :key="child.name">
        <span v-if="child.meta && child.meta.title" slot="title">{{ child.meta.title }}</span>
      </el-menu-item>
    </template>
    <template>
      <el-menu-item  @click="logoutBefore()">
        <span slot="title">退出登录</span>
      </el-menu-item>
    </template>
  </el-submenu>

</template>

<script>
import path from 'path';
import {apiRequest} from '@/api/api'
import md5 from 'blueimp-md5'
import {storage} from '../utils/storage.js'
import getErrorcode from "@/api/common";
import spaceFilter from "@/utils/spaceFilter.js"
import getFileType from "@/utils/getFileType.js"

export default {
  props: {
    // route object
    item: {
      type: Object,
      required: true,
    },
    isNest: {
      type: Boolean,
      default: false,
    },
    basePath: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      onlyOneChild: null,
    };
  },
  methods: {
    toRouter(path){
     this.$router.push(path)
    },
    logout(){
      let params = ({"request_type": "user/logout"});
      apiRequest(params).then((res) => {
        if (res.code == "SUCCESS") {
          storage.setToken("");
          this.$message.success('退出登录成功');
          this.$router.replace('/auth/login')
        } else {
          storage.setToken("");
          this.$message.success('退出登录成功');
          this.$router.replace('/auth/login')
         // this.$message.error(getErrorcode(res.code));
        }
      }).catch((errMsg) => {
        storage.setToken("");
        this.$message.success('退出登录成功');
        this.$router.replace('/auth/login')
      });
    },
    logoutBefore(){
      let params = ({"request_type": "user/logout_before"});
      apiRequest(params).then((res) => {
        if (res.code === "SUCCESS") {
          this.logout()
        } else if (res.code === "E_SET_PASSWORD") {
          this.$message.error(getErrorcode(res.code));
          this.$router.push('/others/fixpass')
        } else {
          //this.$message.error(getErrorcode(res.code));
          storage.setToken("");
          this.$message.success('退出登录成功');
          this.$router.replace('/auth/login')
        }
      }).catch((errMsg) => {
        storage.setToken("");
        this.$message.success('退出登录成功');
        this.$router.replace('/auth/login')
      });
    },
    resolvePath(...paths) {
      return path.resolve(this.basePath, ...paths);
    },
  },
  mounted() {
    // console.log(this.$store.state.user);
  },
};
</script>
<style lang="less" scoped>
.el-menu--collapse .el-menu-item span,
.el-menu--collapse .el-submenu .el-submenu__title span{
  height: 0;
  width: 0;
  overflow: hidden;
  visibility: hidden;
  display: inline-block;
}
.el-submenu, .el-menu-item {
  svg{
    width: 15px;
    margin-right: 5px;
    font-size: 18px;
  }
}
</style>
