<template>
  <el-dialog custom-class="customClass" :visible.sync="showUploadLinks" width="400px">
    <div style="font-size: 16px">检查到新的链接:{{links}}</div>
    <div style="font-size: 16px">是否执行上传操作</div>
    <span slot="footer" class="dialog-footer">
      <el-button round @click="closeDilaog()">取消</el-button>
      <el-button round type="primary" @click="uploadLinks">上传</el-button>
      </span>
  </el-dialog>
</template>

<script>
export default {
  name: "ClipboardDialog",
  props: {
    showUploadLinks: {
      type: Boolean,
      required: false,
    },
    links: {
      type: String,
      required: "",
    },
  },
  methods:{
    closeDilaog(){
      this.showUploadLinks=false
    },
    uploadLinks(){

    }
  }
}
</script>

<style scoped>

</style>