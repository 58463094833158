import {apiRequest} from "@/api/api";
import store from "@/store";
export function clickCourseTask(index) {
    console.log("clickCourseTask",index)
    if(store.state.task==="E_NO_COURSE_TASK"){
        return
    }
    let params = ({"request_type": "course/click_course_task","index":index});
    apiRequest(params).then((res) => {
        if (res.code === "SUCCESS") {
            console.log("clickCourseTask",index)
        }
    })
}
export function showText(msg) {
     window.dispatchEvent(new CustomEvent('app-message', { detail: msg }));
 }
 