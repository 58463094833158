  <template>
    <div>
      <div class="login_body_style">
        <div class="login_body_left">
          <el-image style="width: 800px; height: 533px" :src="url2"></el-image>
          <div style="display: flex" class="qrcodelay">
            <div style="display: flex;flex-direction:column;margin-left: -130px">
              <el-image style="width: 140px; height: 140px" src="https://static.qiankun6.com/cloud/2024/419/pclogo.png"></el-image>
              <a target="_black" href="https://static.qiankun6.com/exe/sign/磁力宅-1.7.5-default-202405281600.exe">
                <el-image style="width: 140px; height: 52px;border-radius:10px;margin-top: 10px"
                          src="https://static.qiankun6.com/cloud/2024/419/pcdownload.png"></el-image>
              </a>
            </div>
            <div style="display: flex;flex-direction:column;margin-left: 15px">
              <el-image style="width: 140px; height: 140px" :src="urlios"></el-image>
              <a target="_black" href="https://www.cilizhai.com/">
                <el-image style="width: 140px; height: 52px;border-radius:10px;margin-top: 10px"
                          src="https://static.qiankun6.com/cloud/2024/419/iosdownload.png"></el-image>
              </a>
            </div>
            <div style="display: flex;flex-direction:column;margin-left: 15px">
              <el-image style="width: 140px; height: 140px" :src="urlandroid"></el-image>
              <a target="_black" href="https://static.qiankun6.com/cloud/2024/513/app-gw_yunying2-release_5314_jiagu_sign.apk">
                <el-image style="width: 140px; height: 52px;border-radius:10px;margin-top: 10px"
                          src="https://static.qiankun6.com/cloud/2023/39/androidbtn.png"></el-image>
              </a>
            </div>
          </div>


        </div>
        <div class="login_body_right">
          <div>
            <el-image style="width: 200px; height: 59px;" :src="url"></el-image>
            <el-card v-if="showLogin" shadow="always" class="login-card">
              <el-tabs v-model="activeName" class="tabs">
                <el-tab-pane :label="isCodeLogin?'快捷登录':'密码登录'" name="first">
                  <div>
                    <el-input maxlength="11" prefix-icon="el-icon-mobile-phone" v-model="phone"
                              placeholder="请输入手机号" @keydown.enter.native="handleEnter"></el-input>
                    <el-input maxlength="6" v-if="isCodeLogin" prefix-icon="el-icon-key" style="margin-top: 5px"
                              placeholder="请输入验证码" v-model="loginCode" @keydown.enter.native="handleEnter">
                      <el-button v-if="!sendMsgDisabled" style="margin-top:4px" size="small" slot="suffix"
                                 :disabled="phone==''"
                                 type="primary" @click="sendLoginCodePre">获取验证码
                      </el-button>
                      <el-button v-if="sendMsgDisabled" style="margin-top:4px" size="small" slot="suffix" disabled
                                 type="primary">{{ time + 's' }}
                      </el-button>
                    </el-input>
                    <el-input v-if="!isCodeLogin" prefix-icon="el-icon-lock" style="margin-top: 5px" placeholder="请输入密码"
                              v-model="password" show-password @keydown.enter.native="handleEnter"></el-input>
                    <el-button style="width: 100%;margin-top: 20px" :disabled="phone==''" type="primary" @click="isCodeLogin?
                  captchaLogin():goLogin()">立即登录
                    </el-button>
                    <div style="display: flex;justify-content: space-between;margin-top: 20px">
                      <span class="login-pass-text"
                            @click="isCodeLogin=!isCodeLogin">{{ !isCodeLogin ? '短信快捷登录>>' : '密码登录>>' }}</span>
                      <span class="login-pass-text" @click="showLogin=false">忘记密码？</span>
                    </div>
                    <div class="login-tips">点击"立即注册"即表示您已同意并遵守 <a href="https://web.miao2021.cn/web/service_agreement.php"
                                                                  target="_blank"
                                                                  style="text-decoration: none"><span
                        style="color: #999999">《磁力宅服务条款》</span></a></div>
                  </div>
                </el-tab-pane>
                <el-tab-pane label="账号注册" name="second">
                  <div>
                    <el-input maxlength="11" prefix-icon="el-icon-mobile-phone" v-model="phone"
                              placeholder="请输入手机号" @keydown.enter.native="register()"></el-input>
                    <el-input maxlength="6" prefix-icon="el-icon-key" style="margin-top: 5px" placeholder="请输入验证码"
                              v-model="registerCode" @keydown.enter.native="register()">
                      <el-button v-if="!sendMsgDisabled" style="margin-top:4px" size="small" slot="suffix"
                                 :disabled="phone==''"
                                 type="primary" @click="sendRegisterCodePre()" @keydown.enter.native="register()">获取验证码
                      </el-button>
                      <el-button v-if="sendMsgDisabled" style="margin-top:4px" size="small" slot="suffix" disabled
                                 type="primary">{{ time + 's' }}
                      </el-button>
                    </el-input>
                    <el-input prefix-icon="el-icon-lock" style="margin-top: 5px" placeholder="请输入密码"
                              v-model="passwordRegister" show-password @keydown.enter.native="register()"></el-input>
                    <el-button type="primary" style="width: 100% ;margin-top: 20px" :disabled="phone==''"
                               @click="register()">
                      立即注册
                    </el-button>
                    <div class="login-tips">点击"立即注册"即表示您已同意并遵守 <a href="https://web.miao2021.cn/web/service_agreement.php"
                                                                  target="_blank"
                                                                  style="text-decoration: none"><span
                        style="color: #999999">《磁力宅服务条款》</span></a></div>
                  </div>
                </el-tab-pane>
              </el-tabs>
            </el-card>
            <el-card v-if="!showLogin" shadow="always" class="login-card">
              <div style="display: flex;flex-direction: column">
                <div>
                  <i class="back el-icon-back" @click="showLogin=true"></i>
                  <span style="margin-left: -30px">修改密码</span>
                </div>
                <el-input style="margin-top: 20px"
                          placeholder="请输入手机号" prefix-icon="el-icon-mobile-phone" maxlength="11"
                          v-model="phone" @keydown.enter.native="resetPass()">

                </el-input>
                <el-input maxlength="6" prefix-icon="el-icon-key" style="margin-top: 5px" placeholder="请输入验证码"
                          v-model="resetCode" @keydown.enter.native="resetPass()">
                  <el-button v-if="!sendMsgDisabled" style="margin-top:4px" size="small" slot="suffix"
                             :disabled="phone==''"
                             type="primary" @click="sendResetCodePre">获取验证码
                  </el-button>
                  <el-button v-if="sendMsgDisabled" style="margin-top:4px" size="small" slot="suffix" disabled
                             type="primary">{{ time + 's' }}
                  </el-button>
                </el-input>
                <el-input prefix-icon="el-icon-lock" style="margin-top:5px" placeholder="请输入新密码" v-model="resetPassword"
                          show-password @keydown.enter.native="resetPass()"></el-input>
                <el-button style="margin-top:30px;" :disabled="phone==''" type="primary" @click="resetPass">修改密码
                </el-button>
              </div>
            </el-card>

          </div>

        </div>

      </div>
    <LoginCaptcha :phone="phone" :type="captchaType" :dialog-visible="showCaptcha" @v-close="handleLoginCaptcha"></LoginCaptcha>
    </div>
  </template>

  <script>
  import {apiRequest} from '../api/api'
  import md5 from 'blueimp-md5'
  import getErrorcode from "../api/common.js"
  import {storage} from "@/utils/storage";
  import {isPhone, isPass, isCaptcha} from "@/utils/formatCheck";
  import LoginCaptcha from "@/components/LoginCaptcha";
  import getQueryString from "@/utils/getQuery";

  export default {
    name: "Login",
    components: {LoginCaptcha},
    data() {
      return {
        captchaType:'',
        showCaptcha:false,
        isCodeLogin: false,
        showLogin: true,
        website: 0,
        phone: '',
        loginCode: '',
        registerCode: '',
        resetCode: '',
        time: 60,
        sendMsgDisabled: false,
        password: '',
        passwordRegister: '',
        resetPassword: '',
        activeName: 'first',
        url: "https://static.qiankun6.com/cloud/2023/37/pclogoclz.png",//https://static.qiankun6.com/cloud/2023/130/pclogo.png
        url2: "https://static.qiankun6.com/cloud/2024/419/pcmain.png",
        urlandroid: "https://php.wzhz.xyz/api_fasd/qr_code?url=https://static.qiankun6.com/cloud/2024/513/app-gw_yunying2-release_5314_jiagu_sign.apk&size=200",
        urlios: "https://php.wzhz.xyz/api_fasd/qr_code?url=https://www.cilizhai.com/&size=200"
      }
    },
    methods: {
      handleEnter(){
        console.log("点击回车")
        if(this.isCodeLogin){
          this.captchaLogin()
        }else {
          this.goLogin()
        }
      },
      sendRegisterCodePre(){
        if (!isPhone(this.phone.trim())) {
          this.$message.error('请输入正确的手机号');
          return
        }
        this.showCaptcha=true
        this.captchaType='register'
      },
      sendLoginCodePre(){
        if (!isPhone(this.phone.trim())) {
          this.$message.error('请输入正确的手机号');
          return
        }
        this.showCaptcha=true
        this.captchaType='login'
      },
      sendResetCodePre(){
        if (!isPhone(this.phone.trim())) {
          this.$message.error('请输入正确的手机号');
          return
        }
        this.showCaptcha=true
        this.captchaType='reset'
      },
      handleLoginCaptcha(obj){
        this.showCaptcha=false;
        if(obj!==null){
          switch (obj.type){
            case 'login':
              this.sendLoginCode(obj.phoneCaptcha)
              break
            case 'register':
              this.sendRegisterCode(obj.phoneCaptcha)
              break
            case 'reset':
              this.sendResetCode(obj.phoneCaptcha)
              break
          }

        }
      },
      goLogin() {
        if (!isPhone(this.phone.trim())) {
          this.$message.error('请输入正确的手机号');
          return
        }
        if (!isPass(this.password.trim())) {
          this.$message.error('请输入正确的6-20位密码，只可包含大小写字母数字符号');
          return
        }
        storage.setToken('')
        var params = ({"request_type": "user/login", "phone": this.phone, "password": md5(this.password)});
        apiRequest(params).then((res) => {

          if (res.code == "SUCCESS") {
            storage.setToken(res.token)
            this.$message.success('登录成功');
            this.$router.replace('/index')
          } else {
            this.$message.error(getErrorcode(res.code));

          }
        });
      },
      sendLoginCode(phone_captcha) {
        if (!isPhone(this.phone.trim())) {
          this.$message.error('请输入正确的手机号');
          return
        }
        var params = ({"request_type": "sms/send_captcha_login_h5", "phone": this.phone,"phone_captcha":phone_captcha});
        apiRequest(params).then((res) => {
          if (res.code === "SUCCESS") {
            this.$message.success('验证码发送成功');
            this.startTime();
          } else {
            this.$message.error(getErrorcode(res.code));

          }
        });
      },
      sendRegisterCode(phone_captcha) {
        if (!isPhone(this.phone.trim())) {
          this.$message.error('请输入正确的手机号');
          return
        }
        var params = ({"request_type": "sms/send_captcha_register_h5", "phone": this.phone,"phone_captcha":phone_captcha});
        apiRequest(params).then((res) => {
          if (res.code === "SUCCESS") {
            this.$message.success('验证码发送成功');
            this.startTime();
          } else {
            this.$message.error(getErrorcode(res.code));

          }
        });
      },
      sendResetCode(phone_captcha) {
        if (!isPhone(this.phone.trim())) {
          this.$message.error('请输入正确的手机号');
          return
        }
        var params = ({"request_type": "sms/send_captcha_reset_password_h5", "phone": this.phone,"phone_captcha":phone_captcha});
        apiRequest(params).then((res) => {
          if (res.code === "SUCCESS") {
            this.$message.success('验证码发送成功');
            this.startTime();
          } else {
            this.$message.error(getErrorcode(res.code));

          }
        });
      },
      captchaLogin() {
        if (!isPhone(this.phone.trim())) {
          this.$message.error('请输入正确的手机号');
          return
        }
        if (!isCaptcha(this.loginCode)) {
          this.$message.error('验证码格式不正确');
          return
        }
        storage.setToken('')
        var params = ({"request_type": "user/captcha_login_pc", "phone": this.phone, "captcha": this.loginCode,"website":this.website,});
        apiRequest(params).then((res) => {

          if (res.code === "SUCCESS") {
            storage.setToken(res.token)
            this.$message.success('登录成功');
           /* if(res.next==="invitation_code"){
              this.$router.replace('/search')
            }else {
              this.$router.replace('/index')
            }*/
            this.$router.replace('/index')

          } else {
            this.$message.error(getErrorcode(res.code));

          }
        });
      },
      resetPass() {
        if (!isPhone(this.phone.trim())) {
          this.$message.error('请输入正确的手机号');
          return
        }
        if (!isPass(this.resetPassword)) {
          this.$message.error('请输入正确的6-20位密码，只可包含大小写字母数字符号');
          return
        }
        if (!isCaptcha(this.resetCode)) {
          this.$message.error('验证码格式不正确');
          return
        }
        storage.setToken('')
        var params = ({
          "request_type": "user/reset_password", "password": md5(this.resetPassword), "phone": this.phone,
          "captcha": this.resetCode
        });
        apiRequest(params).then((res) => {

          if (res.code === "SUCCESS") {
            storage.setToken(res.token)
            this.$message.success('密码重置成功！请重新登录');
            this.showLogin = true
            this.resetCode = ''
            this.resetPassword = ''
          } else {
            this.$message.error(getErrorcode(res.code));

          }
        });
      },
      register() {
        if (!isPhone(this.phone.trim())) {
          this.$message.error('请输入正确的手机号');
          return
        }
        if (!isPass(this.passwordRegister)) {
          this.$message.error('请输入正确的6-20位密码，只可包含大小写字母数字符号');
          return
        }
        if (!isCaptcha(this.registerCode)) {
          this.$message.error('验证码格式不正确');
          return
        }
        storage.setToken('')
        var params = ({
          "request_type": "user/register_pc", "password": md5(this.passwordRegister), "phone": this.phone,
          "captcha": this.registerCode,"website":this.website,
        });
        apiRequest(params).then((res) => {

          if (res.code === "SUCCESS") {
            storage.setToken(res.token)
            this.$message.success('注册成功，安全登录中..');
           // this.$router.replace('/search')
            this.$router.replace('/index')
          } else {
            this.$message.error(getErrorcode(res.code));
          }
        });
      },
      startTime() {
        let me = this;
        me.sendMsgDisabled = true;
        let interval = setInterval(function () {
          if ((me.time--) <= 0) {
            me.time = 60;
            me.sendMsgDisabled = false;
            // me.canPress = true
            clearInterval(interval);
          }
        }, 1000);
      },
    },
    mounted() {
      this.website = getQueryString("website")
      if(this.website == null){
        this.website = ""
      }
      console.log("website"+this.website);
      //this.urlandroid = "https://php.wzhz.xyz/api_fasd/qr_code?url=https://wdc2.info/gotostore.html?website=" + this.website + "%26wangdachui=0.04572124164920166&size=200",
     // this.urlios = "https://php.wzhz.xyz/api_fasd/qr_code?url=https://wdc2.info/gotostore.html?website=" + this.website + "%26wangdachui=0.20266935724343194&size=200"
    }
  }
  </script>

  <style lang="scss" scoped>
  .login_body_style {
    display: flex;
    height: auto;
    position: fixed;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
    width: 100%;
    background: #E9F3FE;

    .login_body_left {
      flex: 2;
      height: 100%;
      display: flex;
      justify-content: center;
      flex-direction: column;
      text-align: center;
      align-items: center;

    }

    .login_body_right {
      flex: 1;
      height: 100%;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;

      .login-card {
        height: 370px;
        width: 380px;
        margin-top: 10px;
        justify-content: center;

        .tabs {
          justify-content: left;
        }

        .login-tips {
          font-size: 12px !important;
          margin-top: 35px;
          color: #999999;
        }

        .login-pass-text {
          color: #3079E7;
          font-size: 12px;
          cursor: pointer;
        }

        .back {
          color: #000000;
          font-size: 22px;
          cursor: pointer;
          float: left;
        }
      }
    }

    .qrcodelay {
      width: auto;
      height: auto;
      position: absolute;
      margin-top: -200px;
    }
  }
  </style>
