export default spaceFilter
function spaceFilter(space_size, toFixed) {
    if (!space_size) {
        return '0B'
    }
    let val = '0B'
    const T = 1024 * 1024 * 1024 * 1024
    const G = 1024 * 1024 * 1024
    const M = 1024 * 1024
    const K = 1024
    toFixed = toFixed || 1
    if (space_size > T) {
        val = (space_size / T).toFixed(toFixed) + 'TB'
    } else if (space_size > G) {
        val = (space_size / G).toFixed(toFixed) + 'GB'
    } else if (space_size > M) {
        val = (space_size / M).toFixed(toFixed) + 'MB'
    } else if (space_size > K) {
        val = (space_size / K).toFixed(toFixed) + 'KB'
    } else { val = space_size + 'B' }
    return val
}