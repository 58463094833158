
export function isPhone(phone){
    return /^(1[0-9])\d{9}$/.test(Number(phone));
}
export function isPass(pass){
    return pass.length>=6&&pass.length<=20;
}
export function isCaptcha(captcha){
    return  /^[0-9]{6}$/gim.test(captcha)
}
export function isHttp(file) {
    if (/(http:\/\/|https:\/\/)((\w|=|\?|\.|\/|&|-)+)/g.test(file)) {
        return true
    }
    return false
}

export function isBtih(file) {

    if (/([a-zA-Z0-9]{40})/g.test(file)) {
        return true
    }
    return false
}
export function getBtih(file){
    var reg = /([a-zA-Z0-9]{40})/g;
    return reg.exec(file)[0].trim();
}