import {apiRequest} from '@/api/api'
import getErrorcode from "@/api/common.js"
import {storage} from "@/utils/storage";
import store from "@/store";
import { Message } from 'element-ui';
export function getUserStatus(){
    var params = ({"request_type": "user/get_user_status_h5"});
    apiRequest(params).then((res) => {
        if (res.code === "SUCCESS") {
            if (res.user_tags && res.user_tags.length > 0) {
                // 找到 user_tag 为 "supreme" 的对象
                const supremeIndex = res.user_tags.findIndex(tag => tag.user_tag === "supreme");
                if (supremeIndex !== -1) {
                    // 将 "supreme" 对象移到队列最前方
                    const supremeTag = res.user_tags.splice(supremeIndex, 1)[0];
                    res.user_tags.unshift(supremeTag);
                }
            }
            if (res.user_tags && res.user_tags.length > 0) {
                // 找到 user_tag 为 "member" 的对象
                const memberIndex = res.user_tags.findIndex(tag => tag.user_tag === "member");
                // 找到 user_tag 为 "eternal" 的对象
                const eternalIndex = res.user_tags.findIndex(tag => tag.user_tag === "eternal");
    
                if (memberIndex !== -1 && eternalIndex !== -1) {
                    // 如果同时存在 "member" 和 "eternal"，则删除 "member"
                    res.user_tags.splice(memberIndex, 1);
                }
            }
            store.commit('updateUserStatus',res)
            for(let i=0;i<res.user_tags.length;i++){
                if(res.user_tags[i].probation===true){
                   // console.log("updateUserExpireSeconds",res.user_tags[i].expire_seconds)
                    store.commit('updateUserExpireSeconds',res.user_tags[i].expire_seconds)
                    return
                }
            }
        } else {
            Message.error(getErrorcode(res.code));
        }
    })

}
