import Vue from 'vue';
import Vuex from 'vuex';
import {apiRequest} from "@/api/api";
import {Message} from "element-ui";
import getErrorcode from "@/api/common";
import store from "@/store";
import {getBtih} from "@/utils/formatCheck";
import {getUserStatus} from "@/utils/getUserStatus";
import Bus from "@/utils/bus";
Vue.use(Vuex)

export default new Vuex.Store({
    state: {
        user_expire_seconds:0,//试用时间
        user: {
            phone: '',
            user_status_btn_tips: '',
            user_tag: '',
            space_used: '',
            space_size: '',
            avatar: '',
            user_tags:[]
        },
        upload: {
            fileList: []
        },
        socket:{
            status:'close'//open
        },
        task:{}
    },
    getters: {
        getPhone: state => state.user.phone,
        getUserStatusTips: state => state.user.user_status_btn_tips,
        getUserTag: state => state.user.user_tag,
        getSpaceUsed: state => state.user.space_used,
        getSpaceSize: state => state.user.space_size,
        getAvatar: state => state.user.avatar,
        getSocketStatus:state=> state.socket.status,
        getUserTags:state=> state.socket.user_tags
    },
    mutations: {
        updateUserExpireSeconds(state, status){
            state.user_expire_seconds=status
        },
        updateTask(state,task){
            state.task=task
        },
        updateSocketStatus(state, status){
            state.socket.status = status
        },
        updateUserStatus(state, res) {
            state.user.phone = res.phone
            state.user.user_status_btn_tips = res.user_status_btn_tips
            state.user.user_tag = res.user_tag
            state.user.space_used = res.space_used
            state.user.space_size = res.space_size
            state.user.avatar = res.avatar
            state.user.user_tags=res.user_tags
        },
        addUploadFiles(state, obj) {
            state.upload.fileList.push(obj)
           // console.log("state.upload.fileList.mutations", state.upload.fileList.length)
        },
        updateUploadFiles(state, status){
            if(state.upload.fileList.length>0){
                state.upload.fileList[0].status=status
            }
        },
        shiftUploadFiles(state){
            if(state.upload.fileList.length>0){
                state.upload.fileList.shift()
            }
        },
        updateUploadFilesProgress(state, progress){
            if(state.upload.fileList.length>0){
                state.upload.fileList[0].progress=progress
            }
        },
    },
    actions: {
        uploadFiles(state, fileObj) {
            window.wcs.getEtagHash(fileObj.file, (file_etag) => {
                let obj = {};
                obj.file_name = fileObj.file.name
                obj.file_size = fileObj.file.size
                obj.file_etag = file_etag
                const split = fileObj.file.name.split('.')
                obj.suffix = split[split.length - 1]
                obj.file = fileObj.file
                obj.progress=0
                obj.path=fileObj.path
                obj.status = 'pending'//pending uploading success error
                state.commit('addUploadFiles', obj)
                dispach(state,obj)
            })
        },

    }
})

//分发任务
function dispach(state,obj) {
    if (store.state.upload.fileList.length > 0) {
        if(store.state.upload.fileList[0].status==='pending'){
            state.commit('updateUploadFiles', 'uploading')
            uploadBaidu(state,obj)
        }
    }
}

function uploadBaidu(state,obj) {//__file, file_etag, file_size, file_ext
    var params = ({
        "request_type": "global/get_sts_session_token",
        "file_etag": obj.file_etag, "file_size": obj.file_size, "file_ext": obj.suffix
    });
    apiRequest(params).then((res) => {
        if (res.code === "SUCCESS") {
            const {bucketName, credentials, endpoint, keyObject, protocol} = res
            const bosConfig = {
                credentials: {
                    ak: credentials.accessKeyId,
                    sk: credentials.secretAccessKey
                },
                sessionToken: credentials.sessionToken,
                endpoint: `${protocol}://${endpoint}`
            }
            const client = new window.baidubce.sdk.BosClient(bosConfig)
            const options = {
                'Content-Type': obj.file.type // 添加http header
            }
            const putObjectDone = (response) => {

                addFile(state,obj.file_name,obj.file_etag,obj.file_size,obj.path)
               // dispach(state,store.state.upload.fileList[0])
            }
            const putObjectFail = (fail) => {
                state.commit('updateUploadFiles', 'error')
            }
            client.putObjectFromBlob(bucketName, keyObject, obj.file, options)
                .then(putObjectDone)
                .catch(putObjectFail)
            client.on('progress', progress => {
                const {loaded, total} = progress
                const percentage = (loaded / total) * 100
                state.commit('updateUploadFilesProgress', percentage)
            })

        } else {
            Message.error(getErrorcode(res.code));
        }
    })

}
//上传图片完毕后添加到文件列表
function addFile(state,filename,file_etag,file_size,file_path){
    let params = ({"request_type": "cloud/add_file_520", "path": file_path, "filename":filename,
        "file_etag":file_etag,"file_size":file_size});
    apiRequest(params).then((res) => {
        if (res.code === "SUCCESS") {
            Bus.$emit('updatefiles', true);
            state.commit('shiftUploadFiles')
            dispach(state,store.state.upload.fileList[0])
        } else if (res.code === "E_INSUFFICIENT_SPACE") {
            Message.error("所需空间不足！");
            Bus.$emit('updatefiles_limit', res);
        } else {
            Message.error(getErrorcode(res.code));
        }
    })
}
