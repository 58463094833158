import Vue from 'vue'
import Router from 'vue-router'

import Login from '../pages/Login'
import layout from '../layout/index'
import Empty from '../layout/Empty.vue';
import EmptyKeepAlive from '../layout/EmptyKeepAlive'
import {storage} from "@/utils/storage";

Vue.use(Router);
const mainRoutes = [
    {
        path: '/index',
        name: 'index',
        component: () => import('../pages/Index'),
        meta: {
            title: '文件',
            icon: 'el-icon-files',
            noCache: true,
        },
    },
    /* {
       path: '/search',
       name: 'search',
       component: () => import('../pages/Search'),
       meta: { title: '搜一搜', icon: 'el-icon-search', perms: ['*'] },
       noCache: true,
     },*/
    {
        path: '/browser',
        name: 'browser',
        component: EmptyKeepAlive,
        meta: {title: '全网搜', icon: 'el-icon-search', perms: ['*']},
        noCache: true,
        children: [{
            path: '/browser/index',
            name: 'browser-index',
            component: () => import('../pages/browser/Index'),
            meta: {title: '全网搜', icon: 'el-icon-search', perms: ['*']},
            noCache: true,
             },
            {
                path: '/browser/browser',
                name: 'browser-browser',
                component: () => import('../pages/browser/Browser'),
                meta: {title: '全网搜', icon: 'el-icon-search', perms: ['*']},
                noCache: true,
            },
            {
                path: '/browser/more',
                name: 'browser-more',
                component: () => import('../pages/browser/CollectAndHistory'),
                meta: {title: '收藏历史', icon: 'el-icon-search', perms: ['*']},
                noCache: true,
            }

        ]
    },
    {
        path: '/usercneter',
        name: 'usercneter',
        component: () => import('../pages/UserCenter'),
        meta: {title: '会员中心', icon: 'el-icon-goods', perms: ['*']},
        noCache: true,
    },
    {
        component: EmptyKeepAlive,
        path: '/others',
        name: 'others',
        meta: {title: '更多设置', icon: 'el-icon-setting', perms: ['*']},
        noCache: true,
        children: [
            {
                path: '/others/useguide',
                name: 'others-useguide',
                component: () => import('../pages/other/UseGuide'),
                meta: {title: '使用指南', perms: ['*']},
                noCache: true,
            },
            {
                path: '/others/fixpass',
                name: 'others-fixpass',
                component: () => import('../pages/other/FixPass'),
                meta: {title: '修改密码', perms: ['*']},
                noCache: true,
            },
            {
                path: '/others/feedback',
                name: 'others-feedback',
                component: () => import('../pages/other/FeedBack'),
                meta: {title: '意见反馈', perms: ['*']},
                noCache: true,
            },
            {
                path: '/others/agreement',
                name: 'others-agreement',
                component: () => import('../pages/other/Agreement'),
                meta: {title: '服务协议', perms: ['*']},
                noCache: true,
            },

        ]
    }
];
const routes = [
    {
        path: '/auth/login',
        name: 'login',
        component: Login,
    },
    {
        path: '',
        name: 'main',
        redirect: '/index',
        component: layout,
        children: mainRoutes,
    },
    {
        path: '*',
        redirect: '/index',
    },
];
export {routes, mainRoutes};
const router = new Router({
    mode: 'hash',
    routes,
});
let lastBrowserChildRoute = null;

router.beforeEach((to, from, next) => {
    if (!storage.getToken() && to.path !== '/auth/login') {
        next('/auth/login');
    } else {
        //console.log("from from.path="+from.path+" from.fullPath="+from.fullPath);
        if (from.path === '/browser/browser'|| from.path === '/browser/index') {
            lastBrowserChildRoute = from.fullPath;
        }
        if (to.path === '/browser') {
            //console.log("beforeEach lastBrowserChildRoute="+lastBrowserChildRoute);

            if (lastBrowserChildRoute) {
                //console.log("beforeEach next="+lastBrowserChildRoute);
                next(lastBrowserChildRoute);
            } else {
               // console.log("beforeEach next="+"index");
                next('/browser/index');
            }
        } else {
            next();
        }
    }

});
export default router;

const originalPush = Router.prototype.push
Router.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}
